














































































































































































































































































































































































































































































































































































































.set-user{
  display: flex;
  min-height: 80vh;
  .set-left{
    width: 224px;
    border-right: 1px solid #e8e8e8;
    box-sizing: border-box;
    margin-right: 40px;
    li{
      height: 40px;
      line-height: 40px;
      padding-left: 24px;
      margin-bottom: 8px;
      margin-right: -1px;
      cursor: pointer;
    }
    li:hover{
      color: #1890ff;
    }
    .isActive{
      background-color: #e6f7ff;
      color: #1890ff;
      border-right: 2px solid #1890ff;
    }
  }
  .set-right{
    flex: 1;
    .tab-title{
      margin-bottom: 30px;
      color: rgba(0,0,0,.85);
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    .base-set{
      .editform{
        .editinput{
          width: 30%;
        }

      }
      .editWarning{
        display: inline-block;
        margin-left: 20px;
        color: #e6a23c;
        font-size: 13px;
        .icon{
          font-size: 15px;
          padding-right: 5px;
          vertical-align: middle;
        }
      }
      .avatar-uploader .el-upload {
        display: block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 144px;
        height: 144px;
        line-height: 144px;
        text-align: center;
        border: 1px dashed #d9d9d9;
        border-radius: 50%;
      }
      .avatar{
        width: 144px;
        height: 144px;
        border-radius: 50%;
      }
    }
    .security-set{
      width: 95%;

      .security-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding: 14px 0;
        color: rgba(0,0,0,.65);
        .item-bottom{
          color: rgba(0,0,0,.45);
        }
      }
    }
  }
  .securityInput{
    width: 80%;
  }
  .msg-text {
    display: block;
    width: 60px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
  .msg-text.display {
    color: #ccc;
  }
}
